import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { archive } from '../../pages/shoppingLists/ShoppingRequests';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { UUID } from 'crypto';

export default function ArchiveLists(props: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleSubmit = async (listID:UUID) => {
  
    const status = await archive(listID);
    if (status === 200) {
      window.location.reload();
    }

  };

  return (
    <React.Fragment>
      <Button style={{ width: '100%' }} onClick={handleClickOpen}>
        Archivierte Listen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { minWidth: 400, maxHeight: 635, minHeight: 250 } }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Archivierte Listen wiederherstellen"}
        </DialogTitle>
        <DialogContent>
          <List>
            {props.archivedLists.map((list: any) =>
            (
              <ListItemButton key={list.id} onClick={() => handleSubmit(list.id)}>
                <ListItemText primary={list.name} />
              </ListItemButton>
            )

            )}

          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}