
import { serverPath } from "../../lib/Store";
import { HitCount, Penalty } from "../../lib/dataTypes/HitCountApp";

export async function getNotReceived() {

const response = await fetch(
    `${serverPath}api/v1/hitCounterApp/notReceived`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
  if (response.status !== 200) {
    return [];
  }
  const data:HitCount[] = await response.json();
  return data;
}

export async function getHitCount(date: string) {
    const response = await fetch(
        `${serverPath}api/v1/hitCounterApp/hitcount/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
    if (response.status !== 200) {
        const data: HitCount = {id: null, number: 0, received: false, receivedNumber: 0, date: new Date()};
        return data;
    }
    const data: HitCount = await response.json();

  return data;
}

export async function getPenalties(date: string) {
    const response = await fetch(
        `${serverPath}api/v1/hitCounterApp/penalties/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status !== 200) {
        return [];
    }
    const data: Penalty[] = await response.json();

  return data;
}