import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function HomeCards(props:any) {
  return (
    <div className='m-3'>
    <Card  sx={{ maxWidth: 345, height:250}} onClick={()=>{window.location.href = props.link}}>
      <CardActionArea sx={{ height:250}}>
        <CardMedia
          component="img"
          sx={{ height: 140, width: 345}}
          image={props.image}
          alt={props.alt}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
  );
}