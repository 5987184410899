import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

export default function AppbarLogin() {
  let button = "" 
  if(window.location.pathname==="/imprint"){
    button = "Login"
  }else{
    button = "Imprint"
  }
  const handleButtonClick = () => {
    if(window.location.pathname==="/imprint"){
      window.location.href="/login"
    }else{
      window.location.href="/imprint"
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color='primary' enableColorOnDark>
        <Toolbar>
          
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            CoupleThings
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button  variant='contained'  component="div" sx={{ flexGrow: 1 }}
            onClick={handleButtonClick}>
              {button}
            </Button>
                        </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}