import React from "react";

import AppBarHome from "../../components/AppBars/AppBar";
import FinanceTable from "./Table";
import { Divider } from "@mui/material";
import AddPayment from "../../components/dialogs/AddPayment";
import { PaymentGetDTO } from "../../lib/dataTypes/Finances";
import { getPayments } from "./requests";
import AddTransfer from "../../components/dialogs/AddTransfer";

export default class Finances extends React.Component {
  state = {
    payments: { payments: [], sum: 0, nora: 0, marcel: 0, openDebts: "Quitt" } as PaymentGetDTO,
  };

  componentDidMount(): void {
    this.load();
  }

  load = async () => {
    const paymentsResponse = await getPayments();
    if (paymentsResponse) {
      this.setState({ payments: paymentsResponse });
    }
    else {
      
    }

  };

  render() {
    return (
      <div>
        <AppBarHome title="Finanzen" />
        <div className="w-screen h-[85vh] flex flex-col justify-starts  items-center">
          <div className="flex flex-col md:flex-row items-center justify-center my-3 text-green-500 ">
            <h1 className="mx-2">Gesamt: {this.state.payments.sum} €</h1>
            <Divider orientation="vertical" flexItem />
            <h1 className="mx-2">Marcel: {this.state.payments.marcel ?? 0} €</h1>
            <Divider orientation="vertical" flexItem />
            <h1 className="mx-2">Nora: {this.state.payments.nora} €</h1>
            <Divider orientation="vertical" flexItem />
            <h1 className="text-red-500 mx-2">{this.state.payments.openDebts}</h1>
            <Divider orientation="vertical" flexItem />
            <div className="flex flex-row">
              <AddPayment />
              <AddTransfer />
            </div>
          </div>
          <FinanceTable data={this.state.payments} />
        </div>
      </div>
    );
  }
}
