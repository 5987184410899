import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import AppbarLogin from '../../components/AppBars/AppBarLogin';
import { serverPath } from '../../lib/Store';




export class Login extends React.Component {
    state = {
        password: '',
        username: ''
    };

    constructor(props:any) {
        super(props);
        

    
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeUsername= this.handleChangeUsername.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      handleChangePassword(event:any) {
        this.setState({ password: event.target.value });
      }
      handleChangeUsername(event:any) {
        this.setState({ username: event.target.value });
      }
    
    async handleSubmit() {
        
        try {
            const response = await fetch(`${serverPath}auth/v1/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: this.state.username, password: this.state.password }),
            })
            const data = await response.text();
            localStorage.setItem('token', data)
            window.location.href = '/';
        } catch (error) {
            console.error('Error:', error);
        }
    }


    render() {
    
        return (
            <div>
            <AppbarLogin/>
            <div className='flex flex-col justify-center items-center h-[85vh] w-screen '>
                <Box
                    component="form"
                    sx={
                        {
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                            'background-color': 'primary',
                        }
                    }
                    noValidate
                    autoComplete="off"

                >
                    <h1>Login</h1>
                    <div className='flex flex-col justify-around items-center h-[20vh]'>
                    <TextField id="outlined-basic" label="Username" variant="filled" onChange={this.handleChangeUsername} />
                    <TextField
          id="filled-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="filled"
          error={false}
          onChange={this.handleChangePassword}
        />
                    <Button onClick={this.handleSubmit} variant="contained">Login</Button>
                    </div>

                </Box>
            </div>
            </div>
        );
    }
}