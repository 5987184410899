import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Payment, PaymentGetDTO } from '../../lib/dataTypes/Finances';
const columns: GridColDef[] = [
  { field: 'name', headerName: 'Sache', minWidth: 200},
    { field: 'amount', headerName: 'Betrag' , minWidth: 150},
    { field: 'customer', headerName: 'Bezahlt von:', minWidth: 200},
    { field: 'category', headerName: 'Kategorie', minWidth: 200},
];

let rows:Payment[] = [];

export default function FinanceTable(props: {data: PaymentGetDTO}) {
  rows = props.data.payments;

  return (
    <div className='w-auto max-w-[98vw]'>
      <DataGrid
        sx={{ minHeight: '20vh' }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 25, 100]}
      />
    </div>
  );
}
