import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { addItemToList } from '../../pages/shoppingLists/ShoppingRequests';

export default function AddShoppingItemToListDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (item:string, count:number) => {
    const listID = props.listID;
    const status = await addItemToList(listID, item, count);
    if (status === 201 || status === 200) {
      handleClose();
      props.reloadtableData();
    }
  }


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button style={{height:'100%', marginRight:'5px'}} variant="contained" onClick={handleClickOpen}>
        Produkt hinzufügen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            let count = Number(formJson.count) ;
            if (count === 0) {
              count = 1;
            }
            const item = formJson.item;
            handleSubmit(item, count);
          },
        }}
      >
        <DialogTitle>Produkt zur Liste hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{margin:'7px'}}>
            Welches Produkt möchten Sie hinzufügen? Das Produkt wird automatisch in die Einkaufsliste hinzugefügt.
            
          </DialogContentText>
          <Autocomplete
              freeSolo
              autoFocus
              autoSelect
              options={props.products}
              renderInput={params => (
                <TextField {...params} label="Artikel" fullWidth name='item' />
              )}
            />
          <TextField
            
            margin="dense"
            id="name"
            name="count"
            label="Anzahl (default 1)"
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button type="submit">Hinzufügen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}