import { serverPath } from "../../lib/Store";
import { CreatePayment, PaymentGetDTO } from "../../lib/dataTypes/Finances";

export async function getPayments(): Promise<PaymentGetDTO | undefined> {
    const response = await fetch(`${serverPath}api/v1/payment`,
    {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    }
    );
    if (response.status === 200) {
        return await response.json() as PaymentGetDTO;
    }
    else{
        return
    }
}
export async function createPayment(payment: CreatePayment): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/payment/expense`,
    {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payment)
    }
    );
    return response.status;
}
export async function createTransfer(payment: CreatePayment): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/payment/transfer`,
    {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payment)
    }
    );
    return response.status;
}
