import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete } from '@mui/material';
import { ShoppingCategories } from '../../lib/autocompleteData.ts/ShoppingItem';
import { createPruduct } from '../../pages/shoppingLists/ShoppingRequests';

export default function CreatePruduct(props:any) {
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async (name:string, category:string) => {
    const status = await createPruduct(name, category);
    if (status === 201) {
      handleClose();
      props.reload();
    }
    if (status === 400) {
      return (
        <Dialog
          open={true}
          onClose={handleClose}
        >
          <DialogTitle>Produkt erstellen</DialogTitle>
          <DialogContentText sx={{margin:'10px'}}>
              Das Produkt existiert bereits
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose}>Schließen</Button>
          </DialogActions>
        </Dialog>
      )

    }
  }

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Produkt Erstellen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const name = formJson.name;
            const category = formJson.category;
            handleCreate(name, category);
          },
        }}
      >
        <DialogTitle>Produkt erstellen</DialogTitle>
        <DialogContentText sx={{margin:'10px'}}>
            Geben sie die genau Bezeichung des Produktes ein
        </DialogContentText>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Produkt Bezeichung"
            fullWidth
            variant="standard"
          />
           <Autocomplete
              freeSolo
              options={ShoppingCategories}
              renderInput={params => (
                <TextField {...params} label="Kategorie" fullWidth name='category' id='category' />
              )}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button type="submit">Erstellen</Button>
        </DialogActions>
      </Dialog>
      
    </React.Fragment>
  );
}