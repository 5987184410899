import * as React from "react";
import AppBarHome from "../../components/AppBars/AppBar";
import Table from "./Table";
import {  Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import AddShoppingItemToListDialog from "../../components/dialogs/AddShoppingItemToList";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ShoppingList, ShoppingListItem } from "../../lib/dataTypes/ShoppingListApp";
import {  getArchievedLists, getProdukts, loadItems, loadLists } from "./ShoppingRequests";
import ShoppingListMorePopover from "../../components/popovers/shoppingListMore";
import ArchiveConfirm from "../../components/dialogs/ArchiveShoppingList";
export class ShoppingLists extends React.Component {
  state = {
    selectedList: "",
    selectedListName: "",
    tableData: [] as ShoppingListItem[],
    listData: [] as ShoppingList[],
    productStrings: [] as string[],
    cssAddProductToList: "hidden",
    checkedshowall: true,
    archivedlists: [] as ShoppingList[],
  };
  componentDidMount(): void {
    this.load();
  }

  load = async () => {
    const data = await loadLists();
    this.setState({ listData: data });
    const produkts = await getProdukts();
    const produktsStrings = produkts.map((product: any) => product.name);
    this.setState({ productStrings: produktsStrings });
    this.setState({ archivedlists: await getArchievedLists() });
    if (localStorage.getItem("selectedList") !== null) {
      this.setState({ selectedList: localStorage.getItem("selectedList") });
      this.setState({ selectedListName: this.state.listData.find((list) => list.id === localStorage.getItem("selectedList"))?.name });
      this.setState({ tableData: await loadItems(localStorage.getItem("selectedList") ?? "") });
      this.setState({ cssAddProductToList: "md:flex md:flex-row" });
    }
  }
  handleCheck = (event: any) => {
    this.setState({ checkedshowall: event.target.checked });
  };


  handleListItemClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ selectedList: event.currentTarget.id });
    this.setState({ selectedListName: event.currentTarget.textContent });
    this.setState({ tableData: await loadItems(event.currentTarget.id) });
    this.setState({ cssAddProductToList: "md:flex md:flex-row" });
    
    localStorage.setItem("selectedList", this.state.selectedList);
  };

  handleTableReload = async () => {
    this.setState({ tableData: await loadItems(localStorage.getItem("selectedList")?? "") });
    this.setState({ cssAddProductToList: "md:flex md:flex-row" });
  };


  render() {
    return (
      <div>
        <AppBarHome title="Einkaufslisten" />
        <div className="flex flex-col md:flex-row justify-start items-center md:items-start md:justify-around mt-5">
          <div className="flex flex-row md:flex-col  items-center  md:items-start justify-start mb-3">
            <div className="mb-3">
              <ShoppingListMorePopover  archivedLists={this.state.archivedlists}/>
            </div>
            <div className="hidden md:flex md:w-[30vw]">
              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'rgb(255,255,255,0.05)' }}>
                <List component="nav" aria-label="main mailbox folders">
                  {this.state.listData.map((item) => {
                    return (
                      <ListItemButton key={item.id}
                        id={item.id.toString()}
                        selected={localStorage.getItem("selectedList") === item.id}
                        onClick={(event) => this.handleListItemClick(event)}
                      >
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    )
                  }
                  )}

                </List>
              </Box>
            </div>
            <div className="md:hidden tex w-[40vw] ml-4">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Einkaufliste</InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    value={this.state.selectedListName}
                    label="Einkaufsliste"
                  >
                    {this.state.listData.map((item) => {
                      return (
                        <MenuItem key={item.id}
                          id={item.id.toString()}
                          selected={localStorage.getItem("selectedList") === item.id}
                          onClick={(event) => this.handleListItemClick(event)}
                          value={item.name}
                        >
                          {item.name}
                        </MenuItem>

                      )
                    }
                    )}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <div>
            <div className="mr-3 flex flex-row justify-end mb-1">
              <div className={this.state.cssAddProductToList}>
                <FormControlLabel label="Alle anzeigen" control={<Checkbox
                  checked={this.state.checkedshowall}
                  onChange={this.handleCheck}
                  inputProps={{ 'aria-label': 'controlled' }}
                />} />
                <div>
                  <AddShoppingItemToListDialog products={this.state.productStrings} listID={localStorage.getItem("selectedList")} reloadtableData={this.handleTableReload} />
                  <ArchiveConfirm listId={localStorage.getItem("selectedList")} />
                </div>
              </div>

              {/* <Button variant="contained" color="primary">
                More Actions
              </Button> */}
            </div>
            <div className="">
              <Table data={this.state.tableData} listID={localStorage.getItem("selectedList") ?? ""} showAll={this.state.checkedshowall} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
