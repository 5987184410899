import * as React from "react";
import AppBarHome from "../../components/AppBars/AppBar";
import ActivityList from "./ActivityList";
import AddActivity from "../../components/dialogs/AddActivity";
import { FormControl, InputLabel, NativeSelect } from "@mui/material";
import dayjs from "dayjs";
import "./yearActivities.css";
export class YearActivities extends React.Component {
  render() {
    return (
      <div>
        <AppBarHome title="Jahres Aktivitäten" />
        <div className="layout-activities">
          <div className="activity-list">
            <div>
              <FormControl>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Jahr
                </InputLabel>
                <NativeSelect
                  defaultValue={dayjs().year()}
                  inputProps={{
                    name: "year",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={2024}>2024</option>
                  <option value={2025}>2025</option>
                  <option value={2026}>2026</option>
                </NativeSelect>
              </FormControl>
              <AddActivity />
            </div>
            <ActivityList />
          </div>

          <div className="activity">
            <h1>Year Activities</h1>
          </div>
        </div>
      </div>
    );
  }
}
