import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { ListItemButton } from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import PaymentsIcon from "@mui/icons-material/Payments";

export const DrawerList = (
  <Box sx={{ width: 250 }} role="presentation">
    <List>
      <ListItem>
        <ListItemButton href="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </ListItem>
    </List>
    <Divider />
    <ListItem>
      <ListItemButton href="/finances">
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="Finanzen" />
      </ListItemButton>
    </ListItem>
    <List>
      <ListItem>
        <ListItemButton href="/hitcounter">
          <ListItemIcon>
            <LocalFireDepartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Hit Counter" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton href="/shoppinglist">
          <ListItemIcon>
            <LocalMallIcon />
          </ListItemIcon>
          <ListItemText primary="Einkaufslisten" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton href="/yearactivities">
          <ListItemIcon>
            <LocalActivityIcon />
          </ListItemIcon>
          <ListItemText primary="Jahres Aktivitäten" />
        </ListItemButton>
      </ListItem>
      <div className="hidden">
      <ListItem >
        <ListItemButton href="/">
          <ListItemIcon>
            <PaymentsIcon />
          </ListItemIcon>
          <ListItemText primary="Wettbüro"/>
        </ListItemButton>
      </ListItem>
      </div>
    </List>
  </Box>
);
