import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Penalty } from '../../lib/dataTypes/HitCountApp';

const style = {
    py: 0,
    width: '100%',
    maxWidth: 360,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    overflow: 'auto'
};

export default function HitCounterList(props: any) {
    const data:Penalty[] = props.data;
    if(data.length === 0){
        return (
            <div>
                <h1>Heute noch keine Strafen erhalten</h1>
            </div>
        );
    }

    return (
        <List sx={style}>
            {data.map((item:Penalty, index) => (
                <div key={index}>
                <ListItem key={index} secondaryAction={
                    <ListItemText primary={item.count} />
                }>
                    <ListItemText primary={item.reason} />
                </ListItem>
                <Divider variant='middle' component="li" />
                </div>
            ))}

        </List>
    );
}