import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { penaltyReasons } from '../../lib/autocompleteData.ts/PeneltyReasons';
import { serverPath } from '../../lib/Store';


export default function AddHits(props: any) {
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const [count, setCount] = React.useState(0);

  const handleCountChange = (event:any) => {
    setCount(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async ()  => {
    const response = await fetch(`${serverPath}api/v1/hitCounterApp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({ date: dayjs(), number: count, reason: reason}),
    })
    if (response.status === 201) {
      props.onDataRecieved();
      handleClose();
    }

  };

  return (
    <React.Fragment>
      <Button variant='contained' onClick={handleClickOpen}>
        Strafe erstellen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiDialog-paper': { minWidth: 400, maxHeight: 635, minHeight: 250 } }}
        PaperProps={{
          component: 'form',
          onSubmit: (event: any) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            handleClose();
          },
        }}
      >
        <DialogTitle>Strafe Hinzufügen</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="email"
              label="Höhe der Strafe"
              type="number"
              fullWidth
              variant="standard"
              onChange={handleCountChange}
            />
            <Autocomplete
              freeSolo
              options={penaltyReasons}
              onInputChange={(event, newReasonValue) => {
                setReason(newReasonValue);
              }}
              renderInput={params => (
                <TextField {...params} label="Grund der Strafe" fullWidth  />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSubmit} type="submit">Hinzufügen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
