import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { archive } from '../../pages/shoppingLists/ShoppingRequests';

export default function ArchiveConfirm(props:any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleSubmit = async ()  => {
    const status = await archive(props.listId);
    if (status === 200) {
      localStorage.removeItem("selectedList");
      window.location.reload();
    }

  };

  return (
    <React.Fragment>
      <Button variant="contained" style={{height:'100%'}} onClick={handleClickOpen}>
        Liste archivieren
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { minWidth: 400, maxHeight: 635, minHeight: 250 } }}
      >
        <DialogTitle id="alert-dialog-title">
            {"Liste wirklich archivieren?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Liste wirklich archivieren? Im moment gibt es noch keine möglichkeit Archivierte Listen wiederherzustellen!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSubmit} autoFocus>
            Ja, wirklich erhalten
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}