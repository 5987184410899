import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { CreatePayment } from '../../lib/dataTypes/Finances';
import { createPayment, createTransfer } from '../../pages/finances/requests';


export default function AddTransfer(props: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (payments:CreatePayment)  => {
    const status = await createTransfer(payments);
    if (status === 200) {
      handleClose();
    }
    

  };

  return (
    <React.Fragment>
      <div className='mx-1'>
      <Button title='Ausgleichstransaktion hinzufügen' variant='contained' onClick={handleClickOpen}>
        <AccountBalanceIcon/>
      </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiDialog-paper': { minWidth: 400, maxHeight: 635, minHeight: 250 } }}
        PaperProps={{
          component: 'form',
          onSubmit: (event: any) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const Payment = {
              name: "Ausgleichstransaktion",
              category: "Ausgleichstransaktion",
              amount: Number(formJson.amount),
              customer: formJson.customer.toString(),
            };
            handleSubmit(Payment);
          },
        }}
      >
        <DialogTitle>Schulden begleichen</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="amount"
              name="amount"
              label="Betrag"
              type="number"
              fullWidth
              variant="standard"
            />
            <Autocomplete
              freeSolo
              options={["Nora","Marcel"]}
              renderInput={params => (
                <TextField {...params} label="Transaktionsrichtung" fullWidth name='customer' id='customer' />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button type="submit">Hinzufügen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
