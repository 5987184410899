import { serverPath } from "../../lib/Store"
import { ShoppingItem, ShoppingListItem } from "../../lib/dataTypes/ShoppingListApp"

export async function loadItems(listId: string) {
    const response = await fetch(`${serverPath}api/v1/shopping/list-items/${listId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    const data:ShoppingListItem[] =  await response.json()
    return data

}


export async function loadLists() {
    const response = await fetch(`${serverPath}api/v1/shopping/lists`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const data = await response.json()
    return data
}

export async function createList(listName: string, own:boolean): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/shopping/lists`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ name: listName, own: own})
    })
    return response.status
}


export async function shopped(ListItemId: string): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/shopping/shopped/${ListItemId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    return response.status
}

export async function archive(listId: string): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/shopping/archive/${listId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    return response.status
}

export async function createPruduct(productName: string, productCategory: string): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/shopping/items`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ name: productName, category: productCategory })
    })
    return response.status
}

export async function getProdukts() {
    const response = await fetch(`${serverPath}api/v1/shopping/items`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const data = await response.json()
    return data
    
}

export async function addItemToList(listId: string, name: string, quantity: number): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/shopping/list-items`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ name: name, listId: listId, quantity: quantity })
    })
    return response.status
}


export async function getArchievedLists() {
    const response = await fetch(`${serverPath}api/v1/shopping/lists-archived`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const data = await response.json()
    return data
}

export async function updateProduct(row:ShoppingItem): Promise<number> {
    const response = await fetch(`${serverPath}api/v1/shopping/items`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(row)
    })
    return  response.status
}