import * as React from "react";
import AppBarHome from "../../components/AppBars/AppBar";
import CreateProduct from "../../components/dialogs/CreateProduct";
import ProductTable from "./ProudctTable";
import { ShoppingItem } from "../../lib/dataTypes/ShoppingListApp";
import { getProdukts } from "../shoppingLists/ShoppingRequests";


export class ShoppingProducts extends React.Component {
  state = {
    tabledata: [] as ShoppingItem[],
  };
  componentDidMount(): void {
    this.load();
  }

  load = async () => {
    const data = await getProdukts();
    this.setState({ tabledata: data });
  }




  render() {
    return (
      <div>
        <AppBarHome title="Produkte verwalten" />
        <div className="w-screen h-[80vh] flex flex-col justify-center items-center">
          <div className="w-screen ml-7 md:w-[60vw]">
          <CreateProduct  reload={this.load}/>
          </div>
          <div className="mt-3">
            <ProductTable data={this.state.tabledata} />
          </div>
        </div>

      </div>

    );
  }
}

