import AppBarHome from "../../components/AppBars/AppBar";
import HomeCards from "../../components/Cards/HomeCards";
import SupermarktImg from "../../img/bild.webp";
import YearActivitiesImg from "../../img/year_activities.webp";
import HitCounterImg from "../../img/hitCounter.png";
import FinancesImg from "../../img/finances.jpg";
import BettingOfficeImg from "../../img/bets.jpeg";
import * as React from "react";
export class Home extends React.Component {
  render() {
    return (
      <div>
        <AppBarHome title="CoupleThings" />
        <div className="flex flex-col justify-center items-center w-screen md:flex-row flex-wrap">
          <HomeCards
            alt="HitCounter"
            title="Hit Counter"
            description="Kris`s Schlagzähler"
            link="/hitcounter"
            image={HitCounterImg}
          />
          <HomeCards
            alt="finances"
            title="Finanz Planner"
            description="Alles rund ums Geld"
            link="/finances"
            image={FinancesImg}
          />
          <HomeCards
            alt="shoppingApp"
            title="Einkaufslisten"
            description="Einkaufslisten für Unterrschiedliche Märkte und Produkte"
            link="/shoppinglist"
            image={SupermarktImg}
          />
          <HomeCards
            alt="HitCounter"
            title="Jahres Aktivitäten"
            description="All das was wir im Aktuellen Jahr schon erlebt haben und noch erleben wolllen"
            link="/yearactivities"
            image={YearActivitiesImg}
          />
          <div hidden>
            <HomeCards
              alt="Wettbuero"
              title="Wettbüro (under Construction)"
              description="Alle 5 € Wetten im Überblick"
              link="/bettingOffice"
              image={BettingOfficeImg}
            />
          </div>
        </div>
      </div>
    );
  }
}
