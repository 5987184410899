import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { List, ListItem } from '@mui/material';
import CreateShoppingListDialog from '../dialogs/CreateShoppingList';
import ArchiveLists from '../dialogs/Reactivate';

export default function ShoppingListMorePopover(props: any) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                Actions
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List>
                    <ListItem>
                        <CreateShoppingListDialog />
                    </ListItem>
                    <ListItem>
                        <Button style={{width:'100%'}} onClick={() => window.location.href = "/products"}>Produkte Verwalten</Button>
                    </ListItem>
                    <ListItem>
                        <ArchiveLists archivedLists={props.archivedLists} />                        
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
}
