import React from 'react';
import AppbarLogin from '../../components/AppBars/AppBarLogin';



export class Imprint extends React.Component {

    render() {
        return (
            <div>
                <AppbarLogin />
                <div className='flex justify-start items-center'>
                    <h1>Imprint</h1>
                    <p>Dies ist eine Webseite zum privaten gebrauch</p>
                    <p>Marcel Bulling</p>
                    <p>Street: Schulzenstr. 4</p>
                    <p>City: 68259 Mannheim</p>
                    <p>Country: Germany</p>
                    <p>E-Mail: marcel_bulling@gmx.de</p>
                </div>
            </div>
        );
    }
}