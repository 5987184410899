export const ShoppingItemdata = [
    'Äpfel','Banane','Salat','Gurke','Zwiebel','Kartoffeln','Tomaten','Knoblauch','Paprika','Zucchini',
    'Feta','Mozarella','Reibe Käse','Boratta',
    'Milch','Hafermilch','Sojamilch',
    'Soja Cuisine', 
    'Mehl','Zucker','Salz','Pfeffer','Olivien Öl','Eier',
    'DM Spagetti', 'DM Farfalle', 'Nudeln', 'Passierte Tomaten',
    'Hackfleisch', 'Lachs', 'Fleisch', 'Veggiefleisch', 'Schinken',
    'Red Bull', 'Saft Marcel', 'Almdudler Zuckerfrei',
    'Müllbeutel', 'Spüli', 'Badreiniger', 'WC Reiniger', 'Putzmittel', 'Geschirrspültabs','Waschmittel'
]


export const ShoppingCategories = [
    "Frühstück","Milchprodukte","Convenience", "Backwaren", "Obst", "Gemüse", "Fleisch", "Fisch", "Getränke","Konserven", "Nudeln", "Gewürze", "Käse","Veggie","Haushalt", "Snacks","Sonstiges"]