import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ShoppingItem } from '../../lib/dataTypes/ShoppingListApp';
import { getProdukts, updateProduct } from '../shoppingLists/ShoppingRequests';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { ShoppingCategories } from '../../lib/autocompleteData.ts/ShoppingItem';

const columns: GridColDef[] = [
  //{ field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Produkt Name',
    editable: true,
    width: 180,
  },
  {
    field: 'category',
    headerName: 'Kategorie',
    editable: true,
    width: 150,
  },
];



type TableProps = {
  data: ShoppingItem[];
};

const ProductTable: React.FC<TableProps> = (props) => {
  const [rows, setRows] = useState<ShoppingItem[]>([]);
  const [selectedRow, setSelectedRow] = useState<ShoppingItem | null>(null);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (name:string, category:string) => {
    const ShoppingItem: ShoppingItem = {
      name: name,
      category: category,
      id: selectedRow?.id
    };

    const status = await updateProduct(ShoppingItem);
    if (status === 200) {
      setOpen(false);
      setRows(await getProdukts());
    }
  };


  useEffect( () => {
    setRows(props.data)
      ;}, [props.data]);

  return (
    <div className='w-screen md:w-[60vw]'>
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowClassName={(params) => {
          if ( params.row.shopped) {
            return 'shopped';
          }
          return '';
        }}
        autosizeOptions={{
          columns: ['name', 'quantity', 'category'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        autoHeight
        pageSizeOptions={[5, 10, 25, 100]}
        onRowDoubleClick={(params) => {
          setSelectedRow(params.row as ShoppingItem);
          setOpen(true);
          
        }
        }
        disableRowSelectionOnClick
        
      />
    </Box>
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const name = formJson.name;
            const category = formJson.category;
            handleUpdate(name, category);
          },
        }}
      >
        <DialogTitle>Produkt erstellen</DialogTitle>
        <DialogContentText sx={{margin:'10px'}}>
            Geben sie die genau Bezeichung des Produktes ein
        </DialogContentText>
        <DialogContent>
          <TextField
            value={selectedRow?.name}
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Produkt Bezeichung"
            fullWidth
            variant="standard"
          />
           <Autocomplete
              value={selectedRow?.category}
              freeSolo
              options={ShoppingCategories}
              renderInput={params => (
                <TextField {...params} label="Kategorie" fullWidth name='category' id='category' />
              )}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button type="submit">Erstellen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductTable;
