import AppBarHome from "../../components/AppBars/AppBar";
import * as React from "react";
export class BettingOffice extends React.Component {
  render() {
    return (
      <div>
        <AppBarHome title="Wettbüro" />
      </div>
    );
  }
}
