import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { serverPath } from '../../lib/Store';

export default function HitReceiveConfirm(props:any) {
  const [open, setOpen] = React.useState(false);
  const [receivedNumber, setReceivedNumber] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceivedNumber(Number(event.target.value));
  }
  const handleSubmit = async ()  => {
    const response = await fetch(`${serverPath}api/v1/hitCounterApp/${props.date}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        receivedNumber: receivedNumber
      })
    })
    if (response.status === 200) {
      props.onDataRecieved();
      handleClose();
    }

  };

  return (
    <React.Fragment>
      <Button sx={{display: props.receivedButton }} variant="contained" onClick={handleClickOpen}>
        Strafe als erhalten markieren
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { minWidth: 400, maxHeight: 635, minHeight: 250 } }}
      >
        <DialogTitle id="alert-dialog-title">
            {"Strafe als erhalten markieren"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hat der Sub die Strafe wirklich erhalten? Wenn der Sub die Strafe noch nicht vollständig erhalten hat, dann trage bitte die bereits 
            erhaltenen Schläge in das nachfolgenede Feld ein. Wenn die Strafe vollständig erhalten wurde, dann kannst du das feld leer lassen.
          </DialogContentText>
          <TextField onChange={handleChange} sx={{margin: '5px'}} id="filled-basic" label="Wie viele wurden erhalten?" variant="filled"  fullWidth type='number'/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSubmit} autoFocus>
            Ja, wirklich erhalten
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}